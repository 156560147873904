// React
import { ReactElement } from 'react';
// Next
import { useTranslation } from 'next-i18next';
// Interface
import { IconsEnum } from '@utmb/types/icons';
// Design System
import { Font } from '@utmb/design-system/text/font';
import { Link } from '@utmb/design-system/link/link';
// Features
import { Icon } from '../icons/Icon';
// Types
import { BreadcrumbsPageView, BreadcrumbsView } from '@utmb/types/index';
// Utils
import { v4 as uuidv4 } from 'uuid';
// Styles
import styles from './breadcrumb.module.scss';

interface BreadcrumbsProps {
    className?: string;
    breadcrumbs: BreadcrumbsView;
}

export const BreadCrumbs = (props: BreadcrumbsProps): ReactElement | null => {
    const { className, breadcrumbs } = props;
    const ContainerClassNames = [styles.breadcrumbs, className].filter(Boolean).join(' ');

    const { t } = useTranslation();

    if (!breadcrumbs.breadcrumbs.length) {
        return null;
    }

    return breadcrumbs.breadcrumbs.length ? (
        <nav className={ContainerClassNames} aria-label={t('common.breadcrumbs')}>
            <ol className="flex">
                {breadcrumbs.breadcrumbs.map((item: BreadcrumbsPageView, idx: number) => (
                    <BreadCrumbsItem item={item} isCurrent={breadcrumbs.breadcrumbs.length - 1 === idx} key={uuidv4()}>
                        <Font.FuturaBook mobile="14" as="span" className={styles.crumb}>
                            {!item.slug && t(`breadcrumbs.${item.title.toLocaleLowerCase()}`) !== `breadcrumbs.${item.title.toLocaleLowerCase()}`
                                ? t(`breadcrumbs.${item.title.toLocaleLowerCase()}`)
                                : item.title}
                        </Font.FuturaBook>
                    </BreadCrumbsItem>
                ))}
            </ol>
        </nav>
    ) : null;
};

interface BreadcrumbsItemProps {
    item: BreadcrumbsPageView;
    isCurrent?: boolean;
    children: ReactElement;
    className?: string;
}

export const BreadCrumbsItem = (props: BreadcrumbsItemProps): ReactElement => {
    const { children, isCurrent, item } = props;

    return (
        <li className={styles.item}>
            {!isCurrent && item.slug ? (
                <Link href={item.slug}>{children}</Link>
            ) : (
                <p className={styles.active} aria-current="page">
                    {children}
                </p>
            )}
            {!isCurrent && <Icon variant={IconsEnum.CHEVRON} />}
        </li>
    );
};
