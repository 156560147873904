import { PiCategoryEnum } from '@utmb/types/enum/PiCategoryEnum';
import { WorldSeriesCategoryEnum } from '@utmb/types/enum/WorldSeriesCategoryEnum';
import { CloudinaryMediaView } from '../types';

export const getRaceCategoryIcon = (cat: PiCategoryEnum | WorldSeriesCategoryEnum, isDark = false): CloudinaryMediaView => {
    const PiCategoryLogo: {
        [K in PiCategoryEnum]: CloudinaryMediaView;
    } = {
        general: {
            alt: 'Index',
            format: 'webp',
            publicId: `Common/categories/index${isDark ? '_bg' : ''}`,
            ratio: 3.927,
            type: 'image',
            width: 856,
            height: 218,
        },
        '100m': {
            alt: '100M',
            format: 'webp',
            publicId: `Common/categories/100M${isDark ? '_bg' : ''}`,
            ratio: 3.555,
            type: 'image',
            width: 775,
            height: 218,
        },
        '100k': {
            alt: '100K',
            format: 'webp',
            publicId: `Common/categories/100K${isDark ? '_bg' : ''}`,
            ratio: 3.417,
            type: 'image',
            width: 745,
            height: 218,
        },
        '50k': {
            alt: '50K',
            format: 'webp',
            publicId: `Common/categories/50K${isDark ? '_bg' : ''}`,
            ratio: 3.101,
            type: 'image',
            width: 676,
            height: 218,
        },
        '20k': {
            alt: '20K',
            format: 'webp',
            publicId: `Common/categories/20K${isDark ? '_bg' : ''}`,
            ratio: 3.101,
            type: 'image',
            width: 676,
            height: 218,
        },
    };

    const RaceCategoryIcon: {
        [K in WorldSeriesCategoryEnum | PiCategoryEnum]: CloudinaryMediaView;
    } = {
        ws100M: PiCategoryLogo['100m'],
        '100m': PiCategoryLogo['100m'],
        ws100KM: PiCategoryLogo['100k'],
        '100k': PiCategoryLogo['100k'],
        ws50KM: PiCategoryLogo['50k'],
        '50k': PiCategoryLogo['50k'],
        ws20KM: PiCategoryLogo['20k'],
        '20k': PiCategoryLogo['20k'],
        general: PiCategoryLogo.general,
    };
    return RaceCategoryIcon[cat];
};
