import { ReactElement } from 'react';
//import { useRouter } from 'next/router';
// Design System
//import { Icon } from '@utmb/design-system/icons/Icon';
import { Image } from '@utmb/design-system/image/Image';
import { Link } from '@utmb/design-system/link/link';
// Features
import { LiveBanners } from '@utmb/features/live-banners/LiveBanners';
import PartnersMenu from '@utmb/features/partners-menu/PartnersMenu';
// Interfaces
//import { IconsEnum } from '@utmb/types/icons';
// Contexts
import { useGlobal } from '@utmb/contexts/GlobalContext';
// Styles
import styles from './mobile-header.module.scss';
import { Font } from '@utmb/design-system/text/font';

interface MobileHeaderProps {
    menuLogoIsDark?: boolean;
}

export const MobileHeader = (props: MobileHeaderProps): ReactElement => {
    const { menuLogoIsDark = false } = props;

    //const { route } = useRouter();
    const { global } = useGlobal();
    const { event, partners } = global;

    //const isHome = route === '/';
    const topbarCSS = [styles.topbar, menuLogoIsDark && styles.is_dark].filter(Boolean).join(' ');

    return (
        <div className={styles.mobile_header}>
            <LiveBanners />
            <div className={topbarCSS}>
                <Link href={`/`} className={styles.home}>
                    <div>
                        <Image objectFit="contain" maxWidth={90} image={!menuLogoIsDark ? event.siteLogo : event.siteLogoDark} />
                    </div>
                    <Font.FuturaHeavy as="div" className={styles.event_date} desktop="16" mobile="12">
                        {!event.isWS ? event.eventDate : ''}
                    </Font.FuturaHeavy>
                </Link>
                {partners && (
                    <div className={styles.partners}>
                        <PartnersMenu partners={partners} menuLogoIsDark={menuLogoIsDark} />
                    </div>
                )}
            </div>
        </div>
    );
};
