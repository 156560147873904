import { ReactElement, useEffect, useRef, useState } from 'react';
import isEmpty from 'lodash.isempty';
import { useRouter } from 'next/router';
import throttle from 'lodash.throttle';
import { useFocus, useHover } from '@react-aria/interactions';
// Types
import { CustomEventAnchorsNavigation } from '@utmb/types/events';
import { GlobalNavigationMenuLevelView } from 'src/types';
import { LayoutProps } from '@utmb/layouts/layout-base';
// Design system
import { BreadCrumbs } from '@utmb/design-system/breadcrumb/Breadcrumb';
import { Font } from '@utmb/design-system/text/font';
import { Image } from '@utmb/design-system/image/Image';
import { Link } from '@utmb/design-system/link/link';
import { Separator } from '@utmb/design-system/separator/Separator';
// Features
import PartnersMenu from '@utmb/features/partners-menu/PartnersMenu';
// Utils
import { SlideInMenu } from './desktop-utils/slide-in-menu';
import { TopBar } from './desktop-utils/top-bar';
// Styles
import styles from './desktop-utils/desktop.module.scss';
import { useGlobal } from '@utmb/contexts/GlobalContext';

export const Desktop = (
    props: Pick<
        LayoutProps,
        'navigation' | 'event' | 'eventsTopBar' | 'languages' | 'translationSlugs' | 'partners' | 'breadcrumbs' | 'menuLogoIsDark' | 'landing'
    >
): ReactElement => {
    const { navigation, event, eventsTopBar, languages, translationSlugs, partners, breadcrumbs, menuLogoIsDark = false } = props;
    const { children } = navigation;

    const {
        global: {
            event: { eventDate },
        },
    } = useGlobal();
    // SLIDE-IN MENU
    const [activeMenu, setActiveMenu] = useState<string | null>(null);

    // Shadow
    const [shadowIsHidden, setHideShadow] = useState<boolean>(false);

    const start = (menuId: string): void => {
        setActiveMenu(menuId);
        document.documentElement.style.setProperty('--menu-is-opened', 'true');
    };
    const end = (): void => {
        setActiveMenu(null);
        document.documentElement.style.setProperty('--menu-is-opened', 'false');
    };

    const { hoverProps } = useHover({
        onHoverStart: ({ target }) => {
            const menuId: string | null = target.getAttribute('data-id');
            if (menuId !== null) {
                start(menuId);
            }
        },
    });
    const { focusProps } = useFocus({
        onFocus: ({ target }) => {
            const menuId: string | null = target.getAttribute('data-id');
            if (menuId !== null) start(menuId);
        },
    });

    // Booleans
    const showSlideInMenu = activeMenu !== null;
    //const hideSlideInMenu = activeMenu === null;

    // STICKY
    const [isSticky, setSticky] = useState<boolean>(false);
    const element = useRef<HTMLElement>(null);

    useEffect(() => {
        let lastScrollPosition = 0;

        const handleScrolling = throttle(() => {
            if (element.current === null) return;
            if (activeMenu !== null) {
                end();
            }
            setSticky(window.scrollY > 70); // element.current.getBoundingClientRect().bottom modified by value 100

            document.documentElement.style.setProperty('--menu-is-sticky', window.scrollY > 100 ? 'true' : 'false');

            // Show/hide the menu.
            const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
            const isScrollingTop = currentScrollPosition < lastScrollPosition;
            const isMenuHidden = element.current.classList.contains(styles.sticky_hidden);
            let menuStickyEventIsHidden = null;

            if (!isScrollingTop && !isMenuHidden && window.scrollY > 300) {
                element.current.classList.add(styles.sticky_hidden);

                menuStickyEventIsHidden = true;
            } else if (isScrollingTop && isMenuHidden) {
                element.current.classList.remove(styles.sticky_hidden);

                menuStickyEventIsHidden = false;
            }

            if (menuStickyEventIsHidden !== null) {
                const event = new CustomEvent('menu-sticky-hidden', {
                    detail: { isHidden: menuStickyEventIsHidden },
                });

                document.dispatchEvent(event);
            }

            lastScrollPosition = Math.max(0, currentScrollPosition);
        }, 0);

        const navbarStickyHandler = (event: CustomEventAnchorsNavigation): void => setHideShadow(event.detail.isSticky);

        document.addEventListener('inner-nav-sticky', navbarStickyHandler);
        window.addEventListener('scroll', handleScrolling);

        return () => {
            document.removeEventListener('inner-nav-sticky', navbarStickyHandler);
            window.removeEventListener('scroll', handleScrolling);
        };
    }, [activeMenu]);

    const showMenuLogoDark = menuLogoIsDark && !isSticky && activeMenu === null;

    const router = useRouter();
    const { pathname } = router;

    const showBreadCrumbs = !showSlideInMenu && !isSticky;

    // NAV HAZ LIGHT/DARK THEME. TODO: confirm it only happens on root / page and [race] landing page.
    const isTransparentNav = pathname === '/races-runners/races/[race]' || pathname == '/' || pathname === '/races/[race]';
    const navigationClassNames = [
        styles.nav,
        isSticky && styles.sticky,
        isTransparentNav && styles.transparent,
        activeMenu !== null && styles.open,
        shadowIsHidden && styles.no_shadow,
    ]
        .filter(Boolean)
        .join(' ');
    const wrapperClassNames = ['utmb_container', styles.nav_container].filter(Boolean).join(' ');

    return (
        <>
            {/* Top Bar */}
            <TopBar eventName={event.name} eventsTopBar={eventsTopBar} languages={languages} translationSlugs={translationSlugs} />

            {/* Main Bar */}
            {element && (
                <div onMouseLeave={() => end()} id="header-bar-menu" className={isSticky ? styles.container : styles.static_container}>
                    <nav className={navigationClassNames} ref={element}>
                        <div className={wrapperClassNames}>
                            <div className={styles.logo}>
                                <Link href={`/`}>
                                    <div className={styles.logo_img_wrapper}>
                                        {/* Image when on top window or menu closed */}
                                        <Image objectFit="contain" image={event.siteLogo} className={showMenuLogoDark ? 'hidden' : ''} maxWidth={240} />
                                        {/* Image when NOT on top window or menu opened */}
                                        <Image objectFit="contain" image={event.siteLogoDark} className={!showMenuLogoDark ? 'hidden' : ''} maxWidth={240} />
                                    </div>
                                </Link>
                            </div>
                            <div className={styles.wrapper}>
                                {eventDate && (
                                    <Font.FuturaHeavy as="div" className={styles.event_date} desktop="16" mobile="12">
                                        {eventDate}
                                    </Font.FuturaHeavy>
                                )}
                                <ul className={styles.menu}>
                                    {children.map((tree: GlobalNavigationMenuLevelView) => {
                                        const isCurrentTreeActive = tree.id.toString() === activeMenu;

                                        if (!tree.children.length && tree.slug !== '/') {
                                            const btnProps = !tree.emphasis ? { ...hoverProps, ...focusProps } : undefined;
                                            return (
                                                <li key={tree.id} className={isCurrentTreeActive ? styles.active : undefined}>
                                                    <button {...btnProps} data-id={tree.id} className={tree.emphasis ? styles.emphasis : ''}>
                                                        <Link
                                                            href={tree.slug}
                                                            className={[tree.emphasis ? '' : styles.menuLink].join(' ')}
                                                            onMouseEnter={() => start(tree.id.toString())}
                                                        >
                                                            <Font.FuturaMedium mobile="16" as="span">
                                                                {tree.label.toUpperCase()}
                                                            </Font.FuturaMedium>
                                                        </Link>
                                                    </button>
                                                </li>
                                            );
                                        }

                                        if (!isEmpty(tree.children)) {
                                            return (
                                                <li key={tree.id} className={isCurrentTreeActive ? styles.active : undefined}>
                                                    <button
                                                        {...hoverProps}
                                                        {...focusProps}
                                                        data-id={tree.id}
                                                        aria-haspopup="true"
                                                        aria-expanded={isCurrentTreeActive}
                                                        className={[tree.emphasis ? styles.emphasis : ''].join(' ')}
                                                    >
                                                        <Font.FuturaMedium mobile="16" as="span">
                                                            {tree.label.toUpperCase()}
                                                        </Font.FuturaMedium>
                                                    </button>
                                                    <SlideInMenu content={tree} cb={end} isSticky={isSticky} isSimpleMenu={false} />
                                                </li>
                                            );
                                        }
                                    })}
                                </ul>
                                {
                                    /*(!isSticky || !hideSlideInMenu) && */ <>
                                        <Separator spacing="-1px 0 0 0" color={/*isTransparentNav && hideSlideInMenu && !isSticky ? '#fff' : */ '#9da7af'} />
                                        <Separator
                                            depth="160px"
                                            color={/*isTransparentNav && hideSlideInMenu && !isSticky ? '#fff' : */ '#9da7af'}
                                            className={styles.separatorAngle}
                                        />
                                    </>
                                }
                                {showBreadCrumbs && breadcrumbs && <BreadCrumbs className={styles.breadcrumbs} breadcrumbs={breadcrumbs} />}
                            </div>
                            {partners && <PartnersMenu partners={partners} menuLogoIsDark={menuLogoIsDark} />}
                        </div>
                    </nav>
                </div>
            )}
        </>
    );
};
