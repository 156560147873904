import { ReactElement, useEffect, useRef, useState } from 'react';
import isEmpty from 'lodash.isempty';
import { useRouter } from 'next/router';
import throttle from 'lodash.throttle';
import { useFocus, useHover } from '@react-aria/interactions';
// Types
import { CustomEventAnchorsNavigation } from '@utmb/types/events';
import { LayoutProps } from '@utmb/layouts/layout-base';
// Design system
import { BreadCrumbs } from '@utmb/design-system/breadcrumb/Breadcrumb';
import { Font } from '@utmb/design-system/text/font';
import { Image } from '@utmb/design-system/image/Image';
import { Link } from '@utmb/design-system/link/link';
import { Separator } from '@utmb/design-system/separator/Separator';
// Features
import PartnersMenu from '@utmb/features/partners-menu/PartnersMenu';
// Utils
import { SlideInMenu } from './desktop-utils/slide-in-menu';
import { TopBar } from './desktop-utils/top-bar';
// Styles
import styles from './desktop-utils/desktop.module.scss';
import { useGlobal } from '@utmb/contexts/GlobalContext';
import GlobalSimpleMenuLevelView from '@utmb/types/view/GlobalSimpleMenuLevelView';
import { PiCategoryEnum } from '@utmb/types/enum/PiCategoryEnum';
import { PiCategoryLogo } from '@utmb/design-system/pi-category-logo/PiCategoryLogo';
import { useTenancy } from '@utmb/contexts/TenancyContext';

export const DesktopSimple = (
    props: Pick<
        LayoutProps,
        'simpleMenu' | 'event' | 'eventsTopBar' | 'languages' | 'translationSlugs' | 'partners' | 'breadcrumbs' | 'menuLogoIsDark' | 'landing'
    >
): ReactElement => {
    const { simpleMenu, event, eventsTopBar, languages, translationSlugs, partners, breadcrumbs, menuLogoIsDark = false, landing } = props;

    const {
        global: {
            event: { eventDate },
        },
    } = useGlobal();
    const { tenantId } = useTenancy();

    // SLIDE-IN MENU
    const [activeMenu, setActiveMenu] = useState<string | null>(null);

    // Shadow
    const [shadowIsHidden, setHideShadow] = useState<boolean>(false);

    const start = (menuId: string): void => {
        setActiveMenu(menuId);
        document.documentElement.style.setProperty('--menu-is-opened', 'true');
    };
    const end = (): void => {
        setActiveMenu(null);
        document.documentElement.style.setProperty('--menu-is-opened', 'false');
    };

    const { hoverProps } = useHover({
        onHoverStart: ({ target }) => {
            const menuId: string | null = target.getAttribute('data-id');
            if (menuId !== null) {
                start(menuId);
            }
        },
    });
    const { focusProps } = useFocus({
        onFocus: ({ target }) => {
            const menuId: string | null = target.getAttribute('data-id');
            if (menuId !== null) start(menuId);
        },
    });

    // Booleans
    const showSlideInMenu = activeMenu !== null;
    //const hideSlideInMenu = activeMenu === null;

    // STICKY
    const [isSticky, setSticky] = useState<boolean>(false);
    const element = useRef<HTMLElement>(null);

    useEffect(() => {
        let lastScrollPosition = 0;

        const handleScrolling = throttle(() => {
            if (element.current === null) return;
            if (activeMenu !== null) {
                end();
            }
            setSticky(window.scrollY > 100); // element.current.getBoundingClientRect().bottom modified by value 100

            document.documentElement.style.setProperty('--menu-is-sticky', window.scrollY > 100 ? 'true' : 'false');

            // Show/hide the menu.
            const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
            const isScrollingTop = currentScrollPosition < lastScrollPosition;
            const isMenuHidden = element.current.classList.contains(styles.sticky_hidden);
            let menuStickyEventIsHidden = null;

            if (!isScrollingTop && !isMenuHidden && window.scrollY > 300) {
                element.current.classList.add(styles.sticky_hidden);

                menuStickyEventIsHidden = true;
            } else if (isScrollingTop && isMenuHidden) {
                element.current.classList.remove(styles.sticky_hidden);

                menuStickyEventIsHidden = false;
            }

            if (menuStickyEventIsHidden !== null) {
                const event = new CustomEvent('menu-sticky-hidden', {
                    detail: { isHidden: menuStickyEventIsHidden },
                });

                document.dispatchEvent(event);
            }

            lastScrollPosition = Math.max(0, currentScrollPosition);
        }, 0);

        const navbarStickyHandler = (event: CustomEventAnchorsNavigation): void => setHideShadow(event.detail.isSticky);

        document.addEventListener('inner-nav-sticky', navbarStickyHandler);
        window.addEventListener('scroll', handleScrolling);

        return () => {
            document.removeEventListener('inner-nav-sticky', navbarStickyHandler);
            window.removeEventListener('scroll', handleScrolling);
        };
    }, [activeMenu]);

    const showMenuLogoDark = menuLogoIsDark && !isSticky && activeMenu === null;

    const router = useRouter();
    const { pathname } = router;

    const showBreadCrumbs = !showSlideInMenu && !isSticky;

    // NAV HAZ LIGHT/DARK THEME. TODO: confirm it only happens on root / page and [race] landing page.
    const isTransparentNav = pathname === '/races-runners/races/[race]' || pathname == '/' || pathname === '/races/[race]';
    const navigationClassNames = [
        styles.nav,
        styles.navSimple,
        isSticky && styles.sticky,
        isTransparentNav && styles.transparent,
        activeMenu !== null && styles.open,
        shadowIsHidden && styles.no_shadow,
    ]
        .filter(Boolean)
        .join(' ');
    const wrapperClassNames = ['utmb_container', styles.nav_container].filter(Boolean).join(' ');

    const isWorldSeries = tenantId === process.env.NEXT_PUBLIC_DEFAULT_TENANCY;

    return (
        <>
            {/* Top Bar */}
            <TopBar eventName={event.name} eventsTopBar={eventsTopBar} languages={languages} translationSlugs={translationSlugs} />

            {/* Main Bar */}
            {element && (
                <div onMouseLeave={() => end()} id="header-bar-menu" className={isSticky ? styles.container : styles.static_container}>
                    <nav className={navigationClassNames} ref={element}>
                        <div className={wrapperClassNames}>
                            {!isWorldSeries && (
                                <div className={styles.logo}>
                                    <Link href={`/`}>
                                        <div className={styles.logo_img_wrapper}>
                                            {/* Image when on top window or menu closed */}
                                            <Image objectFit="contain" image={event.siteLogo} className={showMenuLogoDark ? 'hidden' : ''} maxWidth={240} />
                                            {/* Image when NOT on top window or menu opened */}
                                            <Image
                                                objectFit="contain"
                                                image={event.siteLogoDark}
                                                className={!showMenuLogoDark ? 'hidden' : ''}
                                                maxWidth={240}
                                            />
                                        </div>
                                    </Link>
                                </div>
                            )}
                            <div className={[styles.wrapper, styles.wrapper_simple].join(' ')} style={isWorldSeries ? { marginLeft: '0 !important' } : {}}>
                                {eventDate && (
                                    <Font.FuturaHeavy
                                        as="div"
                                        className={[styles.event_date, styles.event_date_simple, !landing ? styles.event_date_notLanding : ''].join(' ')}
                                        desktop="16"
                                        mobile="12"
                                    >
                                        {eventDate}
                                    </Font.FuturaHeavy>
                                )}
                                <ul className={[styles.menu, styles.menuSimple].join(' ')}>
                                    {simpleMenu?.children.map((tree: GlobalSimpleMenuLevelView) => {
                                        if (!tree.children?.length && tree.slug && tree.label && tree.slug !== '/') {
                                            return (
                                                <li
                                                    key={tree.id}
                                                    data-id={tree.id}
                                                    className={[tree.slug == router.asPath ? styles.currentActiveMenu : '', styles.simple_active].join(' ')}
                                                >
                                                    <button
                                                        {...hoverProps}
                                                        {...focusProps}
                                                        data-id={tree.id}
                                                        aria-haspopup="true"
                                                        onClick={() =>
                                                            tree.slug && tree.slug.substring(0, 5) == 'https'
                                                                ? window.open(tree.slug, '_blank')
                                                                : tree.slug && tree.slug.substring(0, 5) != 'https'
                                                                ? router.push(tree.slug)
                                                                : undefined
                                                        }
                                                        className={[
                                                            tree.slug ? styles.hasLink : styles.hasNoLink,
                                                            styles.boldOnHover,
                                                            tree.emphasis ? styles.emphasis : '',
                                                        ].join(' ')}
                                                    >
                                                        <Font.FuturaMedium mobile="16" as="span">
                                                            {tree.label?.toUpperCase() === 'UTMBINDEX' ? (
                                                                <div style={{ textAlign: 'center' }}>
                                                                    <PiCategoryLogo height={24} piCategory={PiCategoryEnum.GENERAL} />
                                                                </div>
                                                            ) : isWorldSeries && tree.label?.toUpperCase() === 'UTMBWORLDSERIES' ? (
                                                                <div className={styles.utmbws}>
                                                                    <Image
                                                                        objectFit="contain"
                                                                        image={event.siteLogo}
                                                                        className={showMenuLogoDark ? 'hidden' : ''}
                                                                        maxWidth={100}
                                                                    />
                                                                    <Image
                                                                        objectFit="contain"
                                                                        image={event.siteLogoDark}
                                                                        className={!showMenuLogoDark ? 'hidden' : ''}
                                                                        maxWidth={100}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                tree.label?.toUpperCase()
                                                            )}
                                                        </Font.FuturaMedium>
                                                    </button>
                                                </li>
                                            );
                                        }

                                        if (!isEmpty(tree.children)) {
                                            const isCurrentTreeActive = tree.id.toString() === activeMenu;

                                            return (
                                                <li
                                                    key={tree.id}
                                                    className={[
                                                        isCurrentTreeActive ? styles.active : '',
                                                        tree.slug ? styles.hasLink : '',
                                                        styles.simple_active,
                                                    ].join(' ')}
                                                >
                                                    <button
                                                        {...hoverProps}
                                                        {...focusProps}
                                                        data-id={tree.id}
                                                        aria-haspopup="true"
                                                        aria-expanded={isCurrentTreeActive}
                                                        onClick={() =>
                                                            tree.slug && tree.slug.substring(0, 5) == 'https'
                                                                ? window.open(tree.slug, '_blank')
                                                                : tree.slug && tree.slug.substring(0, 5) != 'https'
                                                                ? router.push(tree.slug)
                                                                : undefined
                                                        }
                                                        className={[
                                                            styles.simpleMenuButton,
                                                            tree.slug ? styles.hasLink : styles.hasNoLink,
                                                            styles.boldOnHover,
                                                            tree.emphasis ? styles.emphasis : '',
                                                        ].join(' ')}
                                                    >
                                                        <Font.FuturaMedium mobile="16" as="span">
                                                            {tree.label?.toUpperCase() === 'UTMBINDEX' ? (
                                                                <div style={{ textAlign: 'center' }}>
                                                                    <PiCategoryLogo height={24} piCategory={PiCategoryEnum.GENERAL} />
                                                                </div>
                                                            ) : isWorldSeries && tree.label?.toUpperCase() === 'UTMBWORLDSERIES' ? (
                                                                <div className={styles.utmbws}>
                                                                    <Image
                                                                        objectFit="contain"
                                                                        image={event.siteLogo}
                                                                        className={showMenuLogoDark ? 'hidden' : ''}
                                                                        maxWidth={100}
                                                                    />
                                                                    <Image
                                                                        objectFit="contain"
                                                                        image={event.siteLogoDark}
                                                                        className={!showMenuLogoDark ? 'hidden' : ''}
                                                                        maxWidth={100}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                tree.label?.toUpperCase()
                                                            )}
                                                        </Font.FuturaMedium>
                                                    </button>
                                                    <SlideInMenu content={tree} cb={end} isSticky={isSticky} isSimpleMenu={true} />
                                                </li>
                                            );
                                        }
                                    })}
                                </ul>
                                {
                                    /*(!isSticky || !hideSlideInMenu) && */ <>
                                        <Separator
                                            spacing="-4px 0 0 0"
                                            depth="160px"
                                            color={/*isTransparentNav && hideSlideInMenu ? '#fff' : */ '#9da7af'}
                                            className={styles.separatorAngle}
                                        />
                                    </>
                                }
                                {showBreadCrumbs && breadcrumbs && (
                                    <BreadCrumbs className={[styles.breadcrumbs, styles.breadcrumbsSimple].join(' ')} breadcrumbs={breadcrumbs} />
                                )}
                            </div>
                            {partners && <PartnersMenu partners={partners} menuLogoIsDark={menuLogoIsDark} />}
                        </div>
                    </nav>
                </div>
            )}
        </>
    );
};
