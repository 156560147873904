import { ReactElement, ReactNode, useEffect } from 'react';
import Head from 'next/head';
// Reach
import { SkipNavLink, SkipNavContent } from '@reach/skip-nav';
// Feature
import JsonLd from '@utmb/features/seo/JsonLd';
// Design system
import { Footer } from 'src/features/footer/footer';
import { MobileHeader } from 'src/features/mobile-logo-header/MobileHeader';
import { Row } from 'src/design-system/box/Box';
import { Spacer } from '../design-system/spacer/Spacer';
import { Desktop } from '../features/layout/navigation-public/desktop';
import { Mobile } from '../features/layout/navigation-public/mobile';
// Utils
import { useCloudinary } from 'src/design-system/use-cloudinary/UseCloudinary';
import { LightenDarkenColor } from '@utmb/utils/colors';
// Interfaces
import {
    BreadcrumbsView,
    GlobalEventView,
    GlobalFooterView,
    GlobalMobileBottomBarView,
    GlobalNavigationView,
    EventView,
    GlobalPartnersView,
    SeoView,
} from '../types';
// Styles
import styles from './layout.module.scss';
import { DesktopSimple } from '@utmb/features/layout/navigation-public/desktopSimple';
import GlobalSimpleMenuView from '@utmb/types/view/GlobalSimpleMenuView';
import { MobileSimple } from '@utmb/features/layout/navigation-public/mobileSimple';
import { useRouter } from 'next/router';

//const Desktop = dynamic<any>(() => import('../features/layout/navigation-public/desktop').then((comp) => comp.Desktop as never));
//const Mobile = dynamic<any>(() => import('../features/layout/navigation-public/mobile').then((comp) => comp.Mobile as never));

export interface LayoutProps extends SeoView {
    breadcrumbs?: BreadcrumbsView;
    children: ReactNode;
    event: GlobalEventView;
    eventsTopBar: EventView[];
    languages: string[];
    footer: GlobalFooterView;
    isMobile: boolean;
    landing?: boolean;
    noIndex?: boolean;
    mobileBottomBar: GlobalMobileBottomBarView;
    mobileHeader?: boolean;
    navigation: GlobalNavigationView;
    simpleMenu?: GlobalSimpleMenuView;
    raceTheme?: string;
    raceThemeIsDark?: boolean;
    translationSlugs?: {
        [key: string]: string;
    };
    partners?: GlobalPartnersView;
    menuLogoIsDark?: boolean;
    host?: string;
    contentLocale: string;
}

export const Layout = (props: LayoutProps): ReactElement => {
    const {
        breadcrumbs,
        children,
        event,
        eventsTopBar,
        languages,
        footer,
        isMobile,
        keywords,
        landing = false,
        contentLocale,
        mobileBottomBar,
        mobileHeader = true,
        navigation,
        noIndex,
        simpleMenu,
        description,
        image,
        title,
        translationSlugs,
        raceTheme,
        raceThemeIsDark,
        partners,
        menuLogoIsDark,
        host,
    } = props;
    const { getImage } = useCloudinary();

    // Group navigation data
    const navigationProps = {
        breadcrumbs,
        mobileBottomBar,
        navigation,
        simpleMenu,
        event,
        eventsTopBar,
        languages,
        translationSlugs,
        partners,
        menuLogoIsDark,
        landing,
    };
    const router = useRouter();

    const imageURL = (image?.publicId ? getImage(image?.publicId) : image) as string;

    //const isNavigationReady = (): boolean => navigation !== undefined && mobileBottomBar !== undefined && eventsTopBar !== undefined;

    useEffect(() => {
        if (event?.primaryColor) document.documentElement.style.setProperty('--utmb-color-primary', event.primaryColor);
        if (event?.secondaryColor) document.documentElement.style.setProperty('--utmb-color-secondary', event.secondaryColor);
        if (raceTheme) {
            document.documentElement.style.setProperty('--utmb-color-race-primary', raceTheme);
            document.documentElement.style.setProperty('--utmb-color-race-primary-darken', raceThemeIsDark ? raceTheme : LightenDarkenColor(raceTheme, -20));
        }
        if (event?.isWS) document.body.classList.add('-is-ws');
    }, [event?.isWS, event?.primaryColor, event?.secondaryColor, raceTheme, raceThemeIsDark]);

    const lives = eventsTopBar?.filter((evt) => evt.isLive && evt.urlLive) || [];

    let spacerHeight = isMobile ? '70' : '170';
    let spacerSimpleHeight = isMobile ? '70' : '80';

    if (lives.length && isMobile) {
        spacerHeight = '94';
        spacerSimpleHeight = '94';
    }

    return (
        <>
            <Head>
                <title>{title}</title>
                <meta name="title" content={title} />
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />
                <meta name="og:title" content={title} />
                <meta name="og:description" content={description} />
                <meta name="og:url" content={`https://${host}${!contentLocale || contentLocale === 'en' ? '' : `/${contentLocale}`}${router.asPath}`} />
                {imageURL && <meta name="og:image" content={imageURL} />}
                <meta name="og:type" content="website" />
                <meta name="twitter:site" content="@UTMBWorldSeries" />
                <meta name="twitter:url" content={`https://${host}${!contentLocale || contentLocale === 'en' ? '' : `/${contentLocale}`}${router.asPath}`} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                {imageURL && <meta name="twitter:image" content={imageURL} />}
                <meta name="twitter:card" content="summary_large_image" />
                {noIndex || !event?.isActive || !host ? (
                    <meta name="robots" content="noindex" />
                ) : (
                    <>
                        <meta name="robots" content="index,follow" key="key-bot" />
                        <meta name="googlebot" content="index,follow" key="key-gbot" />
                        {languages?.length > 1 &&
                            languages?.map((lg) => (
                                <link rel="alternate" key={lg} hrefLang={lg} href={`https://${host}${lg === 'en' ? '' : `/${lg}`}${router.asPath}`} />
                            ))}
                        <link rel="alternate" key="alt-x-default" hrefLang="x-default" href={`https://${host}${router.asPath}`} />
                        <link rel="canonical" href={`https://${host}${!contentLocale || contentLocale === 'en' ? '' : `/${contentLocale}`}${router.asPath}`} />
                    </>
                )}
            </Head>
            <JsonLd />
            <SkipNavLink>Skip to Content</SkipNavLink>
            {event?.useSimpleMenu ? (
                <>
                    <DesktopSimple {...navigationProps} />
                    <MobileSimple {...navigationProps} />
                </>
            ) : (
                <>
                    <Desktop {...navigationProps} />
                    <Mobile {...navigationProps} />
                </>
            )}
            <main className={landing ? styles.content_base : styles.content}>
                {!landing && <Spacer custom={event.useSimpleMenu ? spacerSimpleHeight : spacerHeight} />}
                {isMobile && mobileHeader && (
                    <Row className={styles.mobile_header}>
                        <MobileHeader menuLogoIsDark={menuLogoIsDark} />
                    </Row>
                )}
                <SkipNavContent>{children}</SkipNavContent>
            </main>
            {footer && <Footer {...footer} event={event} />}
            {/* <NoSSRCoookie /> */}
        </>
    );
};
