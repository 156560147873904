import { ReactElement } from 'react';
// React aria
import { useHover } from '@react-aria/interactions';
// Design system
import { Font } from 'src/design-system/text/font';
import { Image } from 'src/design-system/image/Image';
import { Link } from 'src/design-system/link/link';
// Interfaces
import { GlobalNavigationMenuLevelView, GlobalNavigationMenuLevel2View, GlobalNavigationMenuLevel3View } from 'src/types';
// Styles
import styles from './desktop.module.scss';
import { useRouter } from 'next/router';
import GlobalSimpleMenuLevelView from '@utmb/types/view/GlobalSimpleMenuLevelView';
import GlobalSimpleMenuLevel2View from '@utmb/types/view/GlobalSimpleMenuLevel2View';

interface SlideInMenuProps {
    content: GlobalNavigationMenuLevelView | GlobalSimpleMenuLevelView;
    cb: () => void;
    isSticky: boolean;
    isSimpleMenu: boolean;
}

export const SlideInMenu = (props: SlideInMenuProps): ReactElement | null => {
    const { content, cb, isSimpleMenu } = props;
    const router = useRouter();
    const { hoverProps } = useHover({
        onHoverStart: () => ({}),
        onHoverEnd: () => cb(),
    });

    return (
        <>
            <div className={isSimpleMenu ? styles.simplemegamenu : styles.megamenu} {...hoverProps}>
                {content.children?.map((subtree: GlobalNavigationMenuLevel2View | GlobalSimpleMenuLevel2View) => {
                    if (subtree.label) {
                        return (
                            <div key={subtree.id} className={isSimpleMenu ? styles.columnSimple : styles.column}>
                                <>
                                    {(subtree as GlobalNavigationMenuLevel2View).thumbnail && (
                                        <div className={styles.thumbnail}>
                                            {/* Add link if there is slug and no label */}
                                            {subtree.slug && !subtree.label ? (
                                                <Link href={subtree.slug} className={styles.link}>
                                                    <Image image={(subtree as GlobalNavigationMenuLevel2View).thumbnail} layout="fill" objectFit="cover" />
                                                </Link>
                                            ) : (
                                                <Image image={(subtree as GlobalNavigationMenuLevel2View).thumbnail} layout="fill" objectFit="cover" />
                                            )}
                                        </div>
                                    )}

                                    {subtree.slug ? (
                                        <Link
                                            href={subtree.slug}
                                            className={[styles.link, subtree.slug == router.asPath ? styles.currentActiveMenu : ''].join(' ')}
                                        >
                                            {isSimpleMenu ? (
                                                <Font.FuturaMedium mobile={'16'} className={styles.subtitle}>
                                                    {subtree.label}
                                                </Font.FuturaMedium>
                                            ) : (
                                                <Font.FuturaHeavy mobile={'24'} className={styles.subtitle}>
                                                    {subtree.label}
                                                </Font.FuturaHeavy>
                                            )}
                                        </Link>
                                    ) : (
                                        <Font.FuturaHeavy mobile="24" className={styles.subtitle}>
                                            {subtree.label}
                                        </Font.FuturaHeavy>
                                    )}
                                </>

                                {!isSimpleMenu &&
                                    !!(subtree as GlobalNavigationMenuLevel2View).children &&
                                    (subtree as GlobalNavigationMenuLevel2View).children.map((lastTree: GlobalNavigationMenuLevel3View) => {
                                        return (
                                            <Link key={lastTree.id} href={`${lastTree.slug}`} className={styles.sub_link}>
                                                <Font.FuturaBook mobile="14">{lastTree.label}</Font.FuturaBook>
                                            </Link>
                                        );
                                    })}
                            </div>
                        );
                    }
                    return (
                        <div key={subtree.id} className={styles.column}>
                            {(subtree as GlobalNavigationMenuLevel2View).thumbnail && (
                                <div className={`${styles.thumbnail} ${styles.full_height}`}>
                                    {subtree.slug ? (
                                        <Link href={`${subtree.slug}`} className={styles.link_full_height}>
                                            <Image image={(subtree as GlobalNavigationMenuLevel2View).thumbnail} layout="fill" objectFit="cover" />
                                        </Link>
                                    ) : (
                                        <Image image={(subtree as GlobalNavigationMenuLevel2View).thumbnail} layout="fill" objectFit="cover" />
                                    )}
                                </div>
                            )}
                            {subtree.label && (
                                <>
                                    {subtree.slug ? (
                                        <Link href={`${subtree.slug}`} className={styles.sub_link}>
                                            <Font.FuturaHeavy mobile="24" className={styles.subtitle}>
                                                {subtree.label}
                                            </Font.FuturaHeavy>
                                        </Link>
                                    ) : (
                                        <Font.FuturaHeavy mobile="24" className={styles.subtitle}>
                                            {subtree.label}
                                        </Font.FuturaHeavy>
                                    )}
                                </>
                            )}
                        </div>
                    );
                })}
            </div>
        </>
    );
};
