import { ReactElement, useEffect, useRef, memo } from 'react';
// Design System
import { Image } from '@utmb/design-system/image/Image';
import { Link } from '@utmb/design-system/link/link';
// Types
import { GlobalPartnersView } from '@utmb/types/index';
// Utils
import { v4 as uuidv4 } from 'uuid';
// Styles
import styles from './partners-menu.module.scss';

interface PartnersProps {
    partners: GlobalPartnersView;
    menuLogoIsDark: boolean;
}

const PartnersMenu = (props: PartnersProps): ReactElement => {
    const {
        partners: { order, partners },
        menuLogoIsDark,
    } = props;

    const container = useRef<HTMLDivElement>(null);

    useEffect(() => {
        let timer: NodeJS.Timer;

        if (container?.current) {
            let currentElement: Element | undefined;
            let currentPosition = 0;
            const partnersElements: Element[] = [...container.current.querySelectorAll(':scope > div')];

            timer = setInterval(() => {
                // Calculate the new position
                currentPosition = currentPosition + 1 >= order.length ? 0 : ++currentPosition;

                // Find the element to work on
                currentElement = partnersElements.find((el) => parseInt(el.getAttribute('data-id') || '0') === order[currentPosition]);

                if (currentElement) {
                    // Hide all partners
                    partnersElements.map((el) => el.classList.add(styles.hidden));

                    // Show the new partner
                    currentElement.classList.remove(styles.hidden);
                }
            }, 4000);
        }

        return () => {
            clearInterval(timer);
        };
    }, [order]);

    return (
        <div ref={container} className={styles.container}>
            {partners.map((partner, index) => {
                const lightImage = <Image layout="fill" objectFit="contain" image={partner.picture} maxWidth={400} />;
                const darkImage = (
                    <Image layout="fill" objectFit="contain" image={menuLogoIsDark ? partner.pictureDark ?? partner.picture : partner.picture} maxWidth={400} />
                );

                const containerCSS = [styles.image_container, index >= 1 && styles.hidden].filter(Boolean).join(' ');
                const imageLightCSS = [menuLogoIsDark && 'hidden', styles.image_light].filter(Boolean).join(' ');
                const imageDarkCSS = [!menuLogoIsDark && 'hidden', styles.image_dark].filter(Boolean).join(' ');

                return (
                    <div key={uuidv4()} data-id={partner.id} className={containerCSS}>
                        <div className={styles.image_wrapper}>
                            {partner.link ? (
                                <Link href={partner.link}>
                                    <div className={imageLightCSS}>{lightImage}</div>
                                    <div className={imageDarkCSS}>{darkImage}</div>
                                </Link>
                            ) : (
                                <>
                                    <div className={imageLightCSS}>{lightImage}</div>
                                    <div className={imageDarkCSS}>{darkImage}</div>
                                </>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default memo(PartnersMenu);
