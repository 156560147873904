// React
import { ReactElement } from 'react';
// Design System
import { Image } from '@utmb/design-system/image/Image';
// Types
import { PiCategoryEnum } from '@utmb/types/enum/PiCategoryEnum';
// Utils
import { rem } from '@utmb/utils/font';
import { getRaceCategoryIcon } from '@utmb/utils/race-categories-icons';
// Styles
import styles from './pi-category-logo.module.scss';

interface PiCategoryLogoProps {
    piCategory: PiCategoryEnum;
    height?: number;
    isDark?: boolean;
}

export const PiCategoryLogo = (props: PiCategoryLogoProps): ReactElement | null => {
    const { piCategory, height, isDark = false } = props;

    const image = getRaceCategoryIcon(piCategory, isDark);

    if (!image) return null;

    const style: any = {};

    if (height) {
        style['height'] = rem(height);
        style['width'] = rem(height * image.ratio);
    }

    return (
        <div className={styles.container} style={style}>
            {height ? (
                <Image layout="responsive" width={image.width} height={image.height} image={image} />
            ) : (
                <Image layout="fill" objectFit="contain" objectPosition="left" image={image} />
            )}
        </div>
    );
};
