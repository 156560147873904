import { ReactElement } from 'react';
import { useGlobal } from '@utmb/contexts/GlobalContext';
import Head from 'next/head';

const JsonLd = (): ReactElement => {
    const {
        global: { event, footer },
    } = useGlobal();

    const jsonLd: { [k in string]: unknown } = {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        url: 'https://utmb.world',
        name: event?.name,
        sameAs: footer?.socialMediaNavigation?.children?.map((s) => s.url),
    };

    if (event?.placeName) {
        jsonLd.address = {
            '@type': 'PostalAddress',
            addressLocality: event?.placeName,
        };
    }

    return (
        <Head>
            <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }} />
        </Head>
    );
};

export default JsonLd;
